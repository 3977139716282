// @ts-nocheck
import Parchment from 'parchment'
import DOMPurify from 'dompurify'
import { textEditorSanitizeConfig } from '@/constants/validate'
import Delta from 'quill-delta'
import { Quill } from '@vueup/vue-quill'

let alignConfig = {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['right', 'center', 'justify'],
}
let AlignAttribute = new Parchment.Attributor.Attribute('align', 'align', alignConfig)
let AlignStyle = new Parchment.Attributor.Style('align', 'text-align', alignConfig)

let directionConfig = {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['rtl'],
}

let DirectionAttribute = new Parchment.Attributor.Attribute('direction', 'dir', directionConfig)
let DirectionStyle = new Parchment.Attributor.Style('direction', 'direction', directionConfig)

let SizeStyle = new Parchment.Attributor.Style('size', 'font-size', {
  scope: Parchment.Scope.INLINE,
  whitelist: ['10px', '18px', '32px'],
})

class ColorAttributor extends Parchment.Attributor.Style {
  value(domNode) {
    let value = super.value(domNode)
    if (!value.startsWith('rgb(')) return value
    value = value.replace(/^[^\d]+/, '').replace(/[^\d]+$/, '')
    return (
      '#' +
      value
        .split(',')
        .map(function (component) {
          return ('00' + parseInt(component).toString(16)).slice(-2)
        })
        .join('')
    )
  }
}

let ColorStyle = new ColorAttributor('color', 'color', {
  scope: Parchment.Scope.INLINE,
})

let BackgroundStyle = new ColorAttributor('background', 'background-color', {
  scope: Parchment.Scope.INLINE,
})

const Clipboard = Quill.import('modules/clipboard')

const ATTRIBUTE_ATTRIBUTORS = [AlignAttribute, DirectionAttribute].reduce(function (memo, attr) {
  memo[attr.keyName] = attr
  return memo
}, {})

const STYLE_ATTRIBUTORS = [AlignStyle, BackgroundStyle, ColorStyle, DirectionStyle, SizeStyle].reduce(function (memo, attr) {
  memo[attr.keyName] = attr
  return memo
}, {})

declare global {
  interface Window {
    clipboardData: any
  }
}

class CustomClipboard extends Clipboard {
  onPaste(e: ClipboardEvent) {
    e.preventDefault()

    const clipboardData = e.clipboardData || window.clipboardData
    const html = clipboardData.getData('text/html')
    const text = clipboardData.getData('text/plain')

    if (!html) {
      // @ts-ignore
      this.quill.insertText(this.quill.getSelection().index, text)
      return
    }
    const purifyContent = DOMPurify.sanitize(html, textEditorSanitizeConfig)
    let range = this.quill.getSelection()
    let delta = new Delta().retain(range.index)
    this.quill.selection.update(Quill.sources.SILENT)
    setTimeout(() => {
      delta = delta.delete(range.length).concat(this.convert(purifyContent))
      this.quill.updateContents(delta, Quill.sources.USER)
      this.quill.setSelection(delta.length() - range.length, Quill.sources.SILENT)
      this.quill.focus()
    }, 0)
  }

  prepareMatching() {
    let elementMatchers = [],
      textMatchers = []
    this.matchers.forEach((pair) => {
      let [selector, matcher] = pair
      switch (selector) {
        case Node.TEXT_NODE:
          textMatchers.push(matcher)
          break
        case Node.ELEMENT_NODE:
          elementMatchers.push(matcher)
          break
        default:
          ;[].forEach.call(this.container.querySelectorAll(selector), (node) => {
            node['__ql-matcher'] = node['__ql-matcher'] || []
            node['__ql-matcher'].push(matcher)
          })
          break
      }
    })
    elementMatchers[3] = matchAttributor
    return [elementMatchers, textMatchers]
  }
}

const matchAttributor = (node, delta) => {
  let attributes = Parchment.Attributor.Attribute.keys(node)
  let classes = Parchment.Attributor.Class.keys(node)
  let styles = Parchment.Attributor.Style.keys(node)
  let formats = {}
  attributes
    .concat(classes)
    .concat(styles)
    .forEach((name) => {
      let attr = Parchment.query(name, Parchment.Scope.ATTRIBUTE)
      if (attr != null) {
        formats[attr.attrName] = attr.value(node)
        if (formats[attr.attrName]) return
      }
      attr = ATTRIBUTE_ATTRIBUTORS[name]
      if (attr != null && (attr.attrName === name || attr.keyName === name)) {
        formats[attr.attrName] = attr.value(node) || undefined
      }
      attr = STYLE_ATTRIBUTORS[name]

      if (attr != null && (attr.attrName === name || attr.keyName === name)) {
        attr = STYLE_ATTRIBUTORS[name]
        const style = window.getComputedStyle(node, null).getPropertyValue(attr.keyName)
        formats[attr.attrName] = style || undefined
      }
    })
  if (Object.keys(formats).length > 0) {
    delta = applyFormat(delta, formats)
  }
  return delta
}

function applyFormat(delta, format, value) {
  if (typeof format === 'object') {
    return Object.keys(format).reduce(function (delta, key) {
      return applyFormat(delta, key, format[key])
    }, delta)
  } else {
    return delta.reduce(function (delta, op) {
      if (op.attributes && op.attributes[format]) {
        return delta.push(op)
      } else {
        return delta.insert(op.insert, Object.assign({}, { [format]: value }, op.attributes))
      }
    }, new Delta())
  }
}

export { CustomClipboard }
